import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: [],
}
const answerSlice = createSlice({
  name: 'answers',
  initialState,
  reducers: {
    addAnswer(state, {payload}) {
        const index = state.data.findIndex(n => n.question_id === payload.question_id);
        if (index === -1) {
            state.data = [...state.data, payload]
        } else {
            state.data[index].answer = payload.answer
        }
    }
  }
})

export const { addAnswer } = answerSlice.actions

export default answerSlice.reducer;
