import React from "react";
import cn from "classnames";

import errorAgeTop from '../../assets/img/errorAgeTop.svg'
import errorAgeBottom from '../../assets/img/errorAgeBottom.svg'
import errorTop from '../../assets/img/errorTop.svg'
import errorBottom from '../../assets/img/errorBottom.svg'

import styles from './index.module.scss';

const ErrorAge = () => {
	return (
		<section className={styles.errorAge}>
			<div className={cn(styles.errorAge__bgTopWrap)}>
				<img src={errorAgeTop} alt="" className={styles.errorAge__bgTop} />
			</div>
			<div className={styles.errorAge__wrap}>
				<img src={errorTop} alt="" className={styles.errorAge__top} />
				<img src={errorBottom} alt="" className={styles.errorAge__bottom} />
				<img src={errorAgeBottom} alt="" className={styles.errorAge__bg} />
			</div>
			<div className={styles.errorAge__text}>
				<p>сТРАНИЦА, КОТОРУЮ ВЫ ИЩИТЕ,
					НЕ МОЖЕТ БЫТЬ НАЙДЕНА.</p>
			</div>
		</section>
	)
}

export default ErrorAge;