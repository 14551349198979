import React from "react";

import desktopBg from '../../assets/img/desktopBg.jpg';

import styles from './index.module.scss';

const ErrorDesktop = () => {
    return (
        <div className={styles.error}>
            <img src={desktopBg} alt="" className={styles.error__bg} />
            <h1 className={styles.error__title}>ДАННЫЙ САЙТ ДОСТУПЕН ТОЛЬКО
                НА МОБИЛЬНЫХ УСТРОЙСТВАХ
                В ПОРТРЕТНОМ РЕЖИМЕ</h1>
        </div>
    )
}

export default ErrorDesktop;