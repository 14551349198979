import React, { useState, useEffect } from "react";
import { Field } from "formik";
import cn from "classnames";
import { useSelector } from "react-redux";

import styles from './index.module.scss';

const Pointfield = ({
    classname,
    formik,
    type,
    label,
    name,
    id,
    value,
    jumpOnQuestion,
    setJump
}) => {
    const [grayScale, setGrayScale] = useState(false);
    const questions = useSelector(state => state.questions);

    useEffect(() => {
        formik.values.answer !== '' && setGrayScale(true);
    }, [formik.values.answer])

    return (
        <div className={cn(classname, styles.pointfield)}>
            <Field
                id={id}
                type={type}
                name={name}
                className={styles.pointfield__input}
                onChange={(e) => {
                    formik.setFieldValue(name, e.target.value)
                    setJump(questions.data.findIndex(q => +q.order === jumpOnQuestion))
                }}
                value={value} />
            <label
                htmlFor={id}
                className={cn(styles.pointfield__label, {
                    [styles.pointfield__label_gray]: grayScale
                })}
            >{label}</label>
        </div>
    )
}

export default Pointfield;