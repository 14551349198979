import React, { useState, useEffect } from "react";
import cn from "classnames";
import { useNavigate } from "react-router-dom";

import novelty from '../../assets/img/secPage/novelty.svg';
import bgPack from '../../assets/img/secPage/bgPack.png'
import pack from '../../assets/img/secPage/pack.png'
import title from '../../assets/img/secPage/title.svg'
import secTitle from '../../assets/img/secPage/secTitle.svg'
import packPrice from '../../assets/img/secPage/packPrice.svg'

import styles from './index.module.scss';
import Button from "../../components/UI/Button";

import { SWIPER_PATH } from "../../constants";

const SecPage = ({ isLoading }) => {
	const navigate = useNavigate();
	const [secStepAnim, setSecStepAnim] = useState(false);
	const [nextStep, setNextStep] = useState(false);
	const [showBtn, setShowbtn] = useState(false);

	useEffect(() => {
		if (!isLoading) {
			setTimeout(() => {
				setSecStepAnim(true);
				setShowbtn(true);
			}, 2000);
		}
	}, []);

	const toNextPage = () => {
		if (!isLoading) {
			setNextStep(true)

			setTimeout(() => {
				navigate(SWIPER_PATH)
			}, 1200);
		}
	}

	return (
		<>
			{!isLoading && (
				<div
					className={styles.secPage}>
					<div className={cn(styles.secPage__novelty, {
						[styles.secPage__novelty_hide]: nextStep
					})}>
						<img src={novelty} alt="" />
					</div>

					<div className={cn(styles.secPage__title, {
						[styles.secPage__title_hide]: nextStep
					})}>
						<img src={title} alt="" />

						<div className={cn(styles.secPage__secTitle, {
							[styles.secPage__secTitle_show]: secStepAnim,
							// [styles.secPage__secTitle_hide]: nextStep
						})}>
							<img src={secTitle} alt="" />
						</div>
					</div>

					<div className={cn(styles.secPage__packBlock, {
						[styles.secPage__packBlock_scale]: secStepAnim,
						[styles.secPage__packBlock_hide]: nextStep
					})}>
						<div className={styles.secPage__bgPack}>
							<img src={bgPack} alt="" />
						</div>

						<div className={styles.secPage__pack}>
							<img src={pack} alt="" />
						</div>

						<div className={cn(styles.secPage__price, {
							[styles.secPage__price_show]: secStepAnim
						})}>
							<img src={packPrice} alt="" />
						</div>
						<div className={cn(styles.secPage__legale, {
							[styles.secPage__legale_show]: secStepAnim
						})}>
							<span>* Компактный удлиненный формат представлен <br /> в портфеле JTI c 2015 года</span>
							<span>** Максимальная розничная цена – июнь-июль 2022</span>
						</div>
					</div>

					<Button
						text='Узнать больше'
						onClick={() => toNextPage()}
						className={cn(styles.secPage__btn, {
							[styles.secPage__btn_active]: showBtn,
							[styles.secPage__btn_show]: secStepAnim,
							[styles.secPage__btn_hide]: nextStep
						})} />
				</div>
			)}
		</>

	)
}

export default SecPage;