import React from "react";

import firstScreen from '../../assets/img/firstScreen.svg';
import firstScreenBottom from '../../assets/img/firstScreenBottom.svg';
import Button from "../../components/UI/Button";

import styles from './index.module.scss';

import { SECPAGE_PATH, ERRORAGE_PATH } from "../../constants";

const FirstPage = () => {

	return (
		<section className={styles.firstScreen}>
			<div className={styles.firstScreen__titleImg}>
				<img src={firstScreen} alt="" />
			</div>
			<div className={styles.firstScreen__bottomImg}>
				<img src={firstScreenBottom} alt="" />
			</div>
			<div className={styles.firstScreen__descr}>
				<p>
					Данный сайт посвящен табачной продукции. Нажимая кнопку “Да”
					вы подтверждаете, что вы старше 18 лет
					и являетесь потребителем табачной продукции.
				</p>
			</div>

			<div className={styles.firstScreen__btns}>
				<Button
					to={ERRORAGE_PATH}
					link={true}
					text='Нет'
					white
					short
				/>
				<Button
					to={SECPAGE_PATH}
					link={true}
					text='Да'
					short
				/>
			</div>
		</section>
	)
}

export default FirstPage;