import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { postAnswers } from "../../api";
import Button from "../../components/UI/Button";

import info from '../../assets/img/finalInfo.svg';

import styles from './index.module.scss';

const FinalScreen = ({ post }) => {
	const answers = useSelector(state => state.answers);

	useEffect(() => {
		if (post) {
			postAnswers(answers.data)
		}
	}, [post]);

	return (
		<div className={styles.final}>

			<div className={styles.final__content}>
				<div className={styles.final__infoWrap}>
					<div className={styles.final__gift}>
						<svg width="264" height="224" viewBox="0 0 264 224" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M211.316 189.726C210.347 189.726 209.568 188.945 209.568 187.987V104.712L165.782 54.8487L54.0595 94.7255V173.564C54.0595 174.527 53.2747 175.303 52.3108 175.303C51.342 175.303 50.5621 174.523 50.5621 173.564V92.2784L166.848 50.7685L213.07 103.406V187.982C213.07 188.945 212.285 189.726 211.316 189.726Z" fill="white" />
							<path d="M96.3091 140.224L51.0658 94.7255C50.3877 94.0415 50.3925 92.9384 51.0804 92.2639C51.7682 91.5895 52.8775 91.5943 53.5557 92.2784L97.3167 136.288L210.812 102.395C211.743 102.115 212.716 102.64 212.992 103.56C213.268 104.48 212.745 105.453 211.82 105.728L96.3091 140.224Z" fill="white" />
							<path d="M96.314 224.236H96.3092C95.7812 224.231 95.3549 223.807 95.3597 223.282L95.8587 138.254C95.8635 137.733 96.2898 137.31 96.8129 137.31H96.8178C97.3458 137.314 97.7721 137.738 97.7672 138.263L97.2683 223.292C97.2634 223.817 96.8372 224.236 96.314 224.236Z" fill="white" />
							<path d="M157.024 206.374H157.019C156.491 206.369 156.065 205.945 156.07 205.42L156.569 120.763L112.265 72.4941C111.912 72.1087 111.936 71.5066 112.328 71.155C112.721 70.7985 113.321 70.8274 113.675 71.2176L158.482 120.035V120.406L157.983 205.435C157.974 205.955 157.547 206.374 157.024 206.374Z" fill="white" />
							<path d="M70.5243 199.418H70.5195C69.9915 199.413 69.5652 198.989 69.57 198.464L70.0738 112.756L185.226 73.7996C185.725 73.631 186.268 73.8959 186.437 74.3921C186.607 74.8883 186.34 75.4278 185.841 75.5964L71.9775 114.12L71.4834 198.474C71.4786 198.994 71.0523 199.418 70.5243 199.418Z" fill="white" />
							<path d="M131.065 119.279C130.905 119.279 130.741 119.255 130.581 119.211C129.651 118.946 129.113 117.983 129.379 117.058C129.408 116.957 132.087 107.414 130.537 95.819C113.055 105.343 78.115 100.424 76.56 100.198C75.6009 100.058 74.9421 99.1766 75.0826 98.2228C75.2231 97.2738 76.1095 96.6139 77.0687 96.7536C77.4174 96.8065 108.521 101.185 125.834 94.1908C115.254 93.4682 95.1611 91.1174 89.7261 83.5785C86.4806 79.0744 84.5721 71.7667 87.6383 65.6055C89.668 61.5254 94.6331 56.5781 106.763 56.212C106.893 56.2072 107.029 56.2072 107.155 56.2072C119.241 56.2072 129.215 73.0722 133.022 89.9517C135.856 87.8032 138.777 85.3802 141.833 82.8367C155.082 71.8197 170.098 59.3383 180.605 70.6732C184.907 75.317 185.803 80.7219 183.197 86.3099C179.147 94.9856 167.507 101.653 160.221 101.653C160.062 101.653 159.897 101.648 159.742 101.643C151.551 101.296 139.242 97.1775 134.001 95.294C135.677 107.636 132.877 117.569 132.746 118.017C132.528 118.783 131.826 119.279 131.065 119.279ZM135.783 92.2302C141.155 94.1282 152.544 97.8567 159.892 98.1698C165.918 98.4348 176.589 92.2013 180.019 84.8503C182.01 80.5822 181.341 76.608 178.028 73.0385C169.779 64.1411 156.714 75.0039 144.076 85.5102C141.334 87.7888 138.52 90.1251 135.783 92.2302ZM107.145 59.6852C107.053 59.6852 106.961 59.6852 106.869 59.69C98.6391 59.9405 93.0734 62.5177 90.7724 67.147C88.3649 71.9883 90.0168 78.005 92.5696 81.5505C97.2731 88.0778 118.965 90.4141 129.651 90.9247C126.182 75.4278 116.964 59.6852 107.145 59.6852Z" fill="white" />
							<path className={styles.animate2} d="M209.238 10.646L217.439 12.0623C217.584 12.0864 217.696 12.1972 217.725 12.3369L219.183 20.3238C219.251 20.6995 219.803 20.6995 219.871 20.3238L221.329 12.3369C221.353 12.1972 221.469 12.0864 221.615 12.0623L229.816 10.646C230.203 10.5786 230.203 10.0391 229.816 9.97644L221.615 8.55538C221.469 8.53129 221.358 8.42049 221.329 8.28079L219.871 0.293861C219.803 -0.081881 219.251 -0.081881 219.183 0.293861L217.725 8.28079C217.701 8.42049 217.584 8.53129 217.439 8.55538L209.238 9.97164C208.851 10.0439 208.851 10.5786 209.238 10.646Z" fill="white" />
							<path className={styles.animate1} d="M28.6525 170.452C28.6525 171.96 29.9071 173.188 31.462 173.188C33.0121 173.188 34.2715 171.965 34.2715 170.452C34.2715 168.944 33.0169 167.716 31.462 167.716C29.9071 167.716 28.6525 168.939 28.6525 170.452Z" fill="white" />
							<path className={styles.animate1} d="M5.3139 142.021C5.3139 143.177 6.27302 144.111 7.45981 144.111C8.6466 144.111 9.60572 143.172 9.60572 142.021C9.60572 140.87 8.6466 139.93 7.45981 139.93C6.27302 139.93 5.3139 140.865 5.3139 142.021Z" fill="white" />
							<path className={styles.animate1} d="M228.697 37.2515C230.247 37.2515 231.506 36.0279 231.506 34.5153C231.506 33.0075 230.247 31.7839 228.697 31.7839C227.147 31.7839 225.887 33.0075 225.887 34.5153C225.892 36.0279 227.147 37.2515 228.697 37.2515Z" fill="white" />
							<path className={styles.animate1} d="M175.562 33.7012L186.723 35.6281C186.922 35.6618 187.077 35.8111 187.111 36.0038L189.092 46.8714C189.184 47.382 189.939 47.382 190.032 46.8714L192.013 36.0038C192.047 35.8111 192.202 35.6618 192.4 35.6281L203.561 33.7012C204.084 33.6097 204.084 32.8774 203.561 32.7859L192.4 30.859C192.202 30.8253 192.051 30.676 192.013 30.4833L190.032 19.6157C189.939 19.105 189.184 19.105 189.092 19.6157L187.111 30.4833C187.077 30.676 186.922 30.8253 186.723 30.859L175.562 32.7859C175.034 32.8726 175.034 33.6097 175.562 33.7012Z" fill="white" />
							<path className={styles.animate2} d="M203.949 51.231C205.76 51.231 207.223 49.8051 207.223 48.042C207.223 46.2789 205.76 44.853 203.949 44.853C202.137 44.853 200.674 46.2789 200.674 48.042C200.674 49.8051 202.142 51.231 203.949 51.231Z" fill="white" />
							<path className={styles.animate2} d="M263.685 43.5523L256.545 42.3191C256.327 42.2806 256.153 42.112 256.114 41.9L254.85 34.9488C254.743 34.3756 253.905 34.3756 253.798 34.9488L252.529 41.9C252.491 42.1168 252.316 42.2806 252.098 42.3191L244.958 43.5523C244.372 43.6535 244.372 44.4724 244.958 44.5784L252.098 45.8116C252.316 45.8502 252.491 46.0188 252.529 46.2355L253.798 53.1868C253.905 53.76 254.743 53.76 254.85 53.1868L256.114 46.2355C256.153 46.0188 256.327 45.8502 256.545 45.8116L263.685 44.5784C264.271 44.4724 264.271 43.6535 263.685 43.5523Z" fill="white" />
							<path className={styles.animate2} d="M29.9265 152.701L22.7864 151.467C22.5684 151.429 22.394 151.26 22.3552 151.048L21.0909 144.097C20.9844 143.524 20.1464 143.524 20.0398 144.097L18.7707 151.048C18.7319 151.265 18.5575 151.429 18.3395 151.467L11.1994 152.701C10.6133 152.802 10.6133 153.621 11.1994 153.727L18.3395 154.96C18.5575 154.998 18.7319 155.167 18.7707 155.384L20.0398 162.335C20.1464 162.908 20.9844 162.908 21.0909 162.335L22.3552 155.384C22.394 155.167 22.5684 154.998 22.7864 154.96L29.9265 153.727C30.5126 153.621 30.5126 152.802 29.9265 152.701Z" fill="white" />
							<path className={styles.animate2} d="M10.5164 164.859L6.54914 164.175C6.42804 164.151 6.33116 164.06 6.30694 163.939L5.60456 160.076C5.54643 159.758 5.07655 159.758 5.01842 160.076L4.31604 163.939C4.29666 164.06 4.19978 164.151 4.07384 164.175L0.106572 164.859C-0.217978 164.917 -0.217978 165.37 0.106572 165.428L4.07384 166.112C4.19494 166.131 4.29182 166.227 4.31604 166.348L5.01842 170.211C5.07655 170.529 5.54643 170.529 5.60456 170.211L6.30694 166.348C6.33116 166.227 6.4232 166.136 6.54914 166.112L10.5164 165.428C10.841 165.375 10.841 164.917 10.5164 164.859Z" fill="white" />
						</svg>
					</div>
					<div className={styles.final__info}>
						<img src={info} alt="" className={styles.final__infoImg} />
					</div>
				</div>
			</div>

			<Button
				onClick={() => window.open("https://ld-info.com/registration/?utm_source=new-pack.club&utm_medium=referral&utm_campaign=ld-connect&utm_content=main-with-survey")}
				white
				text='Пройти регистрацию'
				className={styles.final__btn}
			/>

			<div className={styles.final__legal}>
				<p>* Вознаграждение может быть получено один раз при первичной регистрации</p>
			</div>
		</div>
	)
}

export default FinalScreen;