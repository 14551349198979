import React, { useState, useEffect } from "react";
import cn from "classnames";
import { useSwiper } from 'swiper/react';

import title from '../../../assets/img/swiper/lessSmokeSmell.svg';
import arrowNext from '../../../assets/img/swiper/arrowNextRed.svg';
import "swiper/css";

import styles from './index.module.scss';

const FourthSlide = ({ animate }) => {
	const [isShow, setShow] = useState(false);
	const [showNext, setShownext] = useState(false);
	const swiper = useSwiper();

	useEffect(() => {
		if (animate === 2) {
			setShow(true);

			setTimeout(() => {
				setShownext(true)
			}, 1000);
		}

	}, [animate])

	return (
		<div className={styles.fourthSlide}>
			<div className="container">
				<div className={cn(styles.fourthSlide__title, {
					[styles.fourthSlide__title_show]: isShow
				})}>
					<img src={title} alt="" />
				</div>

				<div className={cn(styles.fourthSlide__img, {
					[styles.fourthSlide__img_show]: isShow
				})}>
					<svg width="75" height="72" viewBox="0 0 75 72" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g clipPath="url(#clip0_429_645)">
							<path className={styles.animate2} d="M68.9015 34.7144C68.838 32.9906 67.3953 31.6452 65.6808 31.709C63.9664 31.7728 62.6282 33.2223 62.6917 34.9472C62.7551 36.671 64.1979 38.0176 65.9124 37.9527C67.6279 37.8889 68.965 36.4393 68.9015 34.7144Z" fill="#D81921" />
							<path className={styles.animate1} d="M52.3739 16.3627L47.7215 17.4955C47.4777 17.5548 47.4888 17.9074 47.736 17.9511L52.4507 18.7805C52.5408 18.7962 52.6143 18.8645 52.6355 18.9551L53.7621 23.6329C53.8211 23.878 54.1718 23.8668 54.2152 23.6183L55.0401 18.8768C55.0557 18.7861 55.1248 18.7122 55.2138 18.691L59.8662 17.5582C60.11 17.4989 60.0989 17.1463 59.8517 17.1026L55.1359 16.272C55.0457 16.2564 54.9722 16.1881 54.9511 16.0974L53.8245 11.4197C53.7654 11.1745 53.4148 11.1857 53.3713 11.4342L52.5453 16.1758C52.5319 16.2676 52.4629 16.3403 52.3739 16.3627Z" fill="#D81921" />
							<path className={styles.animate3} d="M63.2494 7.02726L67.7515 7.81976C67.8383 7.83543 67.9073 7.90036 67.9274 7.98655L69.0028 12.4528C69.0585 12.6867 69.3936 12.6755 69.4348 12.4382L70.223 7.91267C70.2374 7.82536 70.3031 7.75596 70.3888 7.73469L74.8308 6.6534C75.0635 6.59631 75.0523 6.26051 74.8163 6.21909L70.3142 5.42659C70.2274 5.41092 70.1584 5.346 70.1372 5.25981L69.0618 0.793597C69.005 0.559653 68.6711 0.570846 68.6288 0.808149L67.8406 5.3348C67.825 5.42211 67.7604 5.49151 67.6747 5.51278L63.2327 6.59407C63.0023 6.64892 63.0134 6.98585 63.2494 7.02726Z" fill="#D81921" />
							<path className={styles.animate3} d="M74.0604 21.908C74.0237 20.8995 73.1798 20.1137 72.1779 20.1507C71.1748 20.1876 70.3922 21.0361 70.43 22.0435C70.4668 23.052 71.3106 23.8378 72.3126 23.8009C73.3145 23.7639 74.0982 22.9166 74.0604 21.908Z" fill="#D81921" />
							<path className={styles.animate1} d="M58.6706 6.24592C60.385 6.18211 61.7231 4.73255 61.6597 3.00764C61.5962 1.28384 60.1545 -0.0616244 58.439 0.00217857C56.7246 0.0659816 55.3864 1.51554 55.4499 3.24046C55.5144 4.96426 56.9561 6.30972 58.6706 6.24592Z" fill="#D81921" />
							<path className={styles.animate2} d="M34.025 11.4152L38.527 12.2077C38.6139 12.2223 38.6829 12.2883 38.704 12.3745L39.7794 16.8407C39.8362 17.0746 40.1702 17.0634 40.2114 16.8273L40.9996 12.3006C41.0141 12.2133 41.0797 12.1439 41.1655 12.1226L45.6074 11.0413C45.8401 10.9843 45.829 10.6484 45.593 10.607L41.0909 9.81453C41.004 9.79886 40.935 9.73394 40.9139 9.64775L39.8385 5.18154C39.7817 4.94759 39.4477 4.95879 39.4054 5.19609L38.6172 9.72274C38.6016 9.81005 38.537 9.87945 38.4513 9.90072L34.0094 10.982C33.7789 11.0369 33.7889 11.3738 34.025 11.4152Z" fill="#D81921" />
							<path className={styles.animate3} d="M58.6226 28.492C59.6257 28.4551 60.4083 27.6066 60.3705 26.5992C60.3337 25.5907 59.491 24.8049 58.4879 24.8418C57.4849 24.8788 56.7022 25.7272 56.7401 26.7346C56.7768 27.7432 57.6196 28.5301 58.6226 28.492Z" fill="#D81921" />
							<path className={styles.animate1} d="M37.2222 22.9434C38.1251 22.9098 38.8298 22.1464 38.7953 21.2386C38.7619 20.3308 38.0026 19.6223 37.0998 19.657C36.1969 19.6906 35.4933 20.454 35.5267 21.3618C35.5601 22.2696 36.3194 22.9781 37.2222 22.9434Z" fill="#D81921" />
							<path className={styles.animate3} d="M26.2476 11.0099C27.1093 10.9774 27.7817 10.2487 27.7494 9.38344C27.7171 8.51706 26.9935 7.84097 26.1318 7.87344C25.2702 7.9059 24.5977 8.6346 24.63 9.49985C24.6612 10.3662 25.3859 11.0423 26.2476 11.0099Z" fill="#D81921" />
							<path d="M57.9703 45.3281C55.5589 45.3281 51.9219 42.739 48.9873 39.5545C45.2734 35.5248 42.5893 30.4418 44.6466 28.5244C46.7028 26.6081 51.5411 29.684 55.255 33.7148C57.1565 35.7778 58.6805 37.9795 59.5467 39.9149C60.8191 42.758 60.2759 44.1113 59.5956 44.7449C59.1637 45.1467 58.6104 45.3281 57.9703 45.3281ZM46.2931 29.6639C46.0761 29.6639 45.9157 29.7109 45.82 29.8004C45.1866 30.3903 46.1774 33.9521 50.2542 38.3747C54.3299 42.7972 57.7866 44.061 58.42 43.4711C58.6204 43.2841 58.7852 42.4502 57.9703 40.6302C57.1787 38.8627 55.7649 36.8266 53.9859 34.898C50.5269 31.1414 47.5133 29.6639 46.2931 29.6639Z" fill="#D81921" />
							<path d="M53.5951 49.9409C49.0953 53.4579 36.0032 39.1526 38.6907 34.5431C38.7998 34.4043 38.9178 34.27 39.038 34.1458L39.8218 34.9953C39.6726 35.1711 39.6258 35.4901 39.6614 35.8684C40.3194 40.1533 47.7249 48.1958 51.9353 49.1865C52.3082 49.2503 52.6277 49.2279 52.8136 49.0913L53.5951 49.9409Z" fill="#D81921" />
							<path d="M45.8256 29.7948L0 71.9999L44.6411 28.5288L45.8256 29.7948Z" fill="#D81921" />
							<path d="M59.5957 44.7449L38.3355 63.3687L58.42 43.47L59.5957 44.7449Z" fill="#D81921" />
							<path className={styles.animate2} d="M19.165 15.0485L16.1291 15.7872C15.9699 15.8264 15.9777 16.0559 16.1391 16.0839L19.2162 16.6256C19.2752 16.6357 19.3231 16.6805 19.3376 16.7398L20.0723 19.7923C20.1113 19.9523 20.3395 19.9445 20.3673 19.7822L20.9062 16.6883C20.9162 16.629 20.9607 16.5808 21.0197 16.5674L24.0556 15.8275C24.2148 15.7895 24.207 15.5589 24.0467 15.5309L20.9696 14.9891C20.9106 14.9791 20.8627 14.9343 20.8494 14.875L20.1146 11.8225C20.0757 11.6624 19.8474 11.6703 19.8185 11.8326L19.2797 14.9264C19.2674 14.9869 19.2229 15.0339 19.165 15.0485Z" fill="#D81921" />
						</g>
						<defs>
							<clipPath id="clip0_429_645">
								<rect width="75" height="72" fill="white" />
							</clipPath>
						</defs>
					</svg>

				</div>

				<div className={cn(styles.fourthSlide__descr, {
					[styles.fourthSlide__descr_show]: isShow
				})}>
					<p>Исследования показали, что технология Less&nbsp;Smoke&nbsp;Smell способствует уменьшению запаха табачного дыма в окружающем пространстве, а также на волосах и одежде</p>
				</div>
			</div>

			<div
				onClick={() => swiper.slideNext()}
				className={cn(styles.fourthSlide__next, {
					[styles.fourthSlide__next_show]: showNext
				})}>
				<img src={arrowNext} alt="" />
			</div>
		</div>
	)
}

export default FourthSlide;