import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from 'react-router-dom';
import cn from "classnames";

import FirstSlide from "../../components/swiper/FirstSlide";
import SecSlide from "../../components/swiper/SecSlide";
import ThirdSlide from "../../components/swiper/ThirdSlide";
import FourthSlide from "../../components/swiper/FourthSlide";
import FifthSlide from "../../components/swiper/FifthSlide";
import SixthSlide from "../../components/swiper/SixthSlide";

import bgTop from '../../assets/img/grayBgTop.svg'
import bgBottom from '../../assets/img/grayBgBottom.svg'
import styles from './index.module.scss';

import { QUESTIONS_PATH } from "../../constants";

import '../../assets/styles/_ui.scss';

const SwiperPage = () => {
	const navigate = useNavigate();
	const [activeSlide, setActiveSlide] = useState(null);
	const [changeBg, setChangeBg] = useState(null);
	const [hideBg, setHideBg] = useState(false);
	const [rewind, setRewind] = useState(false);

	return (
		<section className={styles.swiper}>
			<div className={cn(styles.swiper__bgTop, {
				[styles.swiper__bgTop_transformUp]: changeBg === false,
				[styles.swiper__bgTop_transformDown]: changeBg === true,
				[styles.swiper__bgTop_hide]: hideBg,
			})}>
				<img src={bgTop} alt="" />
			</div>
			<Swiper
				className={styles.swiper__slider}
				rewind={rewind}
				lazy
				onSlideChange={swiper => {
					setActiveSlide(swiper.activeIndex)

					setChangeBg(swiper.activeIndex !== 0);
					setRewind(swiper.activeIndex === 5);
					setHideBg(swiper.activeIndex === 5);
					setChangeBg(swiper.activeIndex !== 5 && swiper.activeIndex !== 0);

					if (swiper.previousIndex === 5 && swiper.activeIndex !== 4) {
						navigate(`${QUESTIONS_PATH}0`)
					}
				}}>
				<SwiperSlide>
					<FirstSlide />
				</SwiperSlide>
				<SwiperSlide>
					<ThirdSlide animate={activeSlide} />
				</SwiperSlide>
				<SwiperSlide>
					<FourthSlide animate={activeSlide} />
				</SwiperSlide>
				<SwiperSlide>
					<FifthSlide animate={activeSlide} />
				</SwiperSlide>
				<SwiperSlide>
					<SecSlide animate={activeSlide} />
				</SwiperSlide>
				<SwiperSlide>
					<SixthSlide animate={activeSlide} />
				</SwiperSlide>
			</Swiper>
			<div className={cn(styles.swiper__bgBottom, {
				[styles.swiper__bgBottom_hide]: hideBg
			})}>
				<img src={bgBottom} alt="" />
			</div>
		</section >
	)
}

export default SwiperPage;