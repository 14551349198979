import axios from "axios";

const _apiBase = process.env.REACT_APP_API_DOMAIN;

export const getQuestions = async () => {
	const res = await axios(`${_apiBase}/questions`);
	return res.data;
};

export const postAnswers = async answers => {
	return await axios.post(`${_apiBase}/answer`, answers, { headers: { 'content-type': 'application/json' } })
}