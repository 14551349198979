import React, { useState } from "react";
import cn from "classnames";
import { useSwiper } from 'swiper/react';

import title from '../../../assets/img/firstSlide/title.svg';

import leaf from '../../../assets/img/swiper/leaf.svg';
import filter from '../../../assets/img/swiper/filter.svg';
import filterTitle from '../../../assets/img/swiper/filterTitle.svg';
import lss from '../../../assets/img/swiper/lss.svg';
import lssTitle from '../../../assets/img/swiper/lssTitle.svg';
import tabak from '../../../assets/img/swiper/tabak.svg';
import tabakTitle from '../../../assets/img/swiper/tabakTitle.svg';
import taste from '../../../assets/img/swiper/taste.svg';
import arrowNext from '../../../assets/img/swiper/arrowNextRed.svg';

import styles from './index.module.scss';

const FirstSlide = () => {
	const [showNext, setShownext] = useState(false);
	const swiper = useSwiper();

	setTimeout(() => {
		setShownext(true)
	}, 1000);

	return (
		<div className={styles.firstSlide}>
			<div className="container">
				<div className={styles.firstSlide__content}>
					<div className={styles.firstSlide__title}>
						<img src={title} alt="" />
					</div>

					<div className={styles.firstSlide__center}>
						<div className={styles.firstSlide__list}>

							<div className={cn(styles.firstSlide__item, styles.firstSlide__item_1, styles.advItem)}>
								<div className={styles.advItem__imgWrap}>
									<div className={styles.advItem__img}>
										<img src={tabak} alt="" />
									</div>
								</div>
								<div className={styles.advItem__descrWrap}>
									<div className={styles.advItem__title}>
										<img src={tabakTitle} alt="" />
									</div>
									<div className={styles.advItem__descr}>
										<p>в среднем по сравнению с LD Autograph Club Compact Blue</p>
									</div>
								</div>
							</div>

							<div className={cn(styles.firstSlide__item, styles.firstSlide__item_2, styles.advItem)}>
								<div className={styles.advItem__imgWrap}>
									<div className={styles.advItem__img}>
										<img src={lss} alt="" />
									</div>
								</div>
								<div className={styles.advItem__descrWrap}>
									<div className={styles.advItem__title}>
										<img src={lssTitle} alt="" />
									</div>
								</div>
							</div>

							<div className={cn(styles.firstSlide__item, styles.firstSlide__item_3, styles.advItem)}>
								<div className={styles.advItem__imgWrap}>
									<div className={styles.advItem__img}>
										<img src={filter} alt="" />
									</div>
								</div>
								<div className={styles.advItem__descrWrap}>
									<div className={styles.advItem__title}>
										<img src={filterTitle} alt="" />
									</div>
								</div>
							</div>

							<div className={cn(styles.firstSlide__item, styles.firstSlide__item_4, styles.advItem)}>
								<div className={styles.advItem__imgWrap}>
									<div className={styles.advItem__img}>
										<img src={leaf} alt="" />
									</div>
									<div className={styles.advItem__img}>
										<img src={leaf} alt="" />
									</div>
									<div className={styles.advItem__img}>
										<img src={leaf} alt="" />
									</div>
								</div>
								<div className={styles.advItem__descrWrap}>
									<div className={styles.advItem__title}>
										<img src={taste} alt="" />
									</div>
									<div className={styles.advItem__descr}>
										<p>мг/сиг., примерное содержание по данным изготовителя</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>


				<div className={styles.firstSlide__legal}>
					<p>* Меньше запаха табачного дыма</p>
				</div>

				<div
					onClick={() => swiper.slideNext()}
					className={cn(styles.firstSlide__next, {
						[styles.firstSlide__next_show]: showNext
					})}>
					<img src={arrowNext} alt="" />
				</div>

			</div>
		</div>
	)
}

export default FirstSlide;