import React, { useState, useEffect } from "react";
import cn from "classnames";
import { useSwiper } from 'swiper/react';

import leaf from '../../../assets/img/swiper/leaf.svg';
import taste from '../../../assets/img/swiper/tasteTitle.svg';
import arrowNext from '../../../assets/img/swiper/arrowNextRed.svg';
import "swiper/css";

import styles from './index.module.scss';

const SecSlide = ({ animate }) => {
	const [isShow, setShow] = useState(false);
	const [showNext, setShownext] = useState(false);
	const swiper = useSwiper();

	useEffect(() => {
		if (animate === 4) {
			setShow(true);

			setTimeout(() => {
				setShownext(true)
			}, 1000);
		}
	}, [animate])

	return (
		<div className={styles.secSlide}>
			<div className="container">
				<div className={cn(styles.secSlide__title, {
					[styles.secSlide__title_show]: isShow
				})}>
					<img src={taste} alt="" />
				</div>

				<div className={cn(styles.secSlide__leafList)}>
					<div className={cn(styles.secSlide__leaf, {
						[styles.secSlide__leaf_show]: isShow
					})}>
						<img src={leaf} alt="" />
					</div>

					<div className={cn(styles.secSlide__leaf, styles.secSlide__leaf_2, {
						[styles.secSlide__leaf_2_show]: isShow
					})}>
						<img src={leaf} alt="" />
					</div>

					<div className={cn(styles.secSlide__leaf, styles.secSlide__leaf_3, {
						[styles.secSlide__leaf_3_show]: isShow
					})}>
						<img src={leaf} alt="" />
					</div>
				</div>

				<div className={cn(styles.secSlide__descr, {
					[styles.secSlide__descr_show]: isShow
				})}>
					<p>мг/сиг., примерное содержание
						по данным изготовителя</p>
				</div>
			</div>

			<div
				onClick={() => swiper.slideNext()}
				className={cn(styles.secSlide__next, {
					[styles.secSlide__next_show]: showNext
				})}>
				<img src={arrowNext} alt="" />
			</div>
		</div>
	)
}

export default SecSlide;