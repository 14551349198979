const envRoute = `${process.env.PUBLIC_URL}`;

const checkSlash = (url) => {
    if (!!url) {
        return url.substr(-1) !== '/' ? url + '/' : url;
    }
    return undefined;
}

const HOME_PATH = checkSlash(envRoute) || '/';
const SWIPER_PATH = `${checkSlash(envRoute) || '/'}swiper/`;
const ERRORAGE_PATH = `${checkSlash(envRoute) || '/'}error-age/`;
const SECPAGE_PATH = `${checkSlash(envRoute) || '/'}benefits/`;
const QUESTIONS_PATH = `${checkSlash(envRoute) || '/'}questions/`;
const ERROR_PATH = `${checkSlash(envRoute) || '/'}404/`;
const FINAL_PATH = `${checkSlash(envRoute) || '/'}final/`;

export {
    HOME_PATH,
    SWIPER_PATH,
    ERRORAGE_PATH,
    SECPAGE_PATH,
    QUESTIONS_PATH,
    ERROR_PATH,
    FINAL_PATH,
};
  