import { createSlice } from '@reduxjs/toolkit';

import { getQuestions as getQuestionsApi } from '../api';

const initialState = {
	data: [],
	isLoading: false,
	isLoaded: false,
	error: null
};

export const slice = createSlice({
	name: 'questions',
	initialState,
	reducers: {
        getDataStart: state => {
			state.isLoading = true;
		},
		getDataSuccess: (state, { payload }) => {
			state.data = payload
			state.isLoading = false;
			state.isLoaded = true;
		},
		getDataFailure: (state, { payload }) => {
			state.error = payload;
			state.isLoading = false;
		}
    }
});

const { getDataStart, getDataSuccess, getDataFailure } = slice.actions;

export const getQuestions = () => async dispatch => {
    dispatch(getDataStart());
	try {
		const data = await getQuestionsApi();
        dispatch(getDataSuccess(data));
	} catch (err) {
        dispatch(getDataFailure({ error: err.toString() }));
	}
};

export const selectQuestions = state => state.data;

export default slice.reducer;
