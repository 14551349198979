import React, { useState, useEffect } from "react";
import cn from "classnames";
import { useSwiper } from 'swiper/react';

import moreTabaka from '../../../assets/img/swiper/moreTabaka.svg';
import arrowNext from '../../../assets/img/swiper/arrowNextRed.svg';
import styles from './index.module.scss';

const ThirdSlide = ({ animate }) => {
	const [isShow, setShow] = useState(false);
	const [showNext, setShownext] = useState(false);
	const swiper = useSwiper();

	useEffect(() => {
		if (animate === 1) {
			setShow(true);

			setTimeout(() => {
				setShownext(true)
			}, 1000);
		}
	}, [animate])

	return (
		<div className={styles.thirdSlide}>
			<div className="container">
				<div className={cn(styles.thirdSlide__title, {
					[styles.thirdSlide__title_show]: isShow
				})}>
					<img src={moreTabaka} alt="" />
				</div>

				<div className={cn(styles.thirdSlide__img, {
					[styles.thirdSlide__img_show]: isShow
				})}>
					<svg width="103" height="47" viewBox="0 0 103 47" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M102.87 21.4499C102.587 20.9435 101.986 20.885 101.785 20.885C100.113 20.773 85.2545 19.8576 77.5875 22.044C68.7964 24.5566 67.0381 32.1335 65.6594 41.7799C65.6546 41.8091 65.6594 41.8432 65.6594 41.8724L59.6786 45.2907C59.3183 45.4953 59.1934 45.9579 59.3952 46.3231C59.5057 46.5178 59.6834 46.6445 59.8852 46.6931C60.0581 46.7321 60.2455 46.7078 60.4136 46.6152L66.3944 43.192C66.4233 43.2066 66.4425 43.2261 66.4713 43.2407C69.7331 44.6334 72.7836 45.8361 75.6659 46.4886C80.6187 47.6037 85.0863 47.0876 89.2993 43.0849C95.0976 37.5678 102.01 24.1914 102.76 22.7257C102.89 22.5066 103.154 21.9514 102.87 21.4499ZM95.6212 32.2747L89.2513 30.1126L100.608 23.6168C99.57 25.5646 97.7542 28.8709 95.6212 32.2747ZM91.7109 38.0206L81.517 34.539L88.2953 30.6629L95.1697 33.0002C94.0648 34.724 92.8926 36.4527 91.7109 38.0206ZM89.136 22.1657L87.5555 29.3384L80.7772 33.2194L83.1071 22.5699C85.0431 22.3507 87.1088 22.229 89.136 22.1657ZM99.8679 22.2923L88.5115 28.7882L89.9767 22.1413C93.9447 22.0537 97.6725 22.1852 99.8679 22.2923ZM77.991 23.4999C79.2352 23.1444 80.6908 22.8766 82.2472 22.6721L79.8213 33.7599L73.24 37.524L76.2424 24.1232C76.79 23.8943 77.3713 23.6801 77.991 23.4999ZM75.3008 24.5761L72.2744 38.0791L67.288 40.9326C68.4121 33.4677 69.9205 27.4491 75.3008 24.5761ZM68.0325 42.2522L73.0142 39.3987L85.9798 43.752C80.7724 46.9415 74.9117 45.1398 68.0325 42.2522ZM88.2809 41.9746C87.8053 42.4226 87.3297 42.8171 86.8445 43.1725L73.975 38.8484L80.5611 35.0795L91.1777 38.7072C90.2025 39.9587 89.2225 41.0787 88.2809 41.9746Z" fill="#D81921" />
						<path className={styles.pulse} d="M12.346 19.8965L13.2971 13.7756H7.10014L6.14416 19.8965H0.850287L0 25.5889H5.24584L4.34271 31.7098H10.5445L11.4428 25.5889H16.8472L17.6927 19.8965H12.346Z" fill="#D81921" />
						<path d="M32.7913 0.618408H30.7257C24.0002 0.618408 21.5599 4.43119 20.8729 8.99388L20.0274 14.5256H29.2413L29.933 9.90933C30.0915 9.10587 30.2501 8.72606 30.937 8.72606C31.6288 8.72606 31.893 9.15944 31.7873 9.9629L31.5183 11.7354C31.2541 13.2936 30.6728 14.4185 28.1844 17.1064L23.9474 21.5084C17.9569 28.0042 17.2171 30.3659 16.6359 34.2323L15.5742 41.108H37.403L39.4687 27.8971H30.62L29.933 32.6254H25.9602C26.1187 31.3885 26.3301 30.9064 32.58 25.2676C34.2229 23.8165 35.5488 22.6868 37.0283 21.2406C40.6313 17.6956 41.1068 15.7624 41.8514 11.6818L42.1157 9.9629C43.014 4.16337 39.8386 0.618408 32.7913 0.618408Z" fill="#D81921" />
						<path d="M64.3624 10.07L65.7939 1.15405H45.3966L41.9042 23.6023H51.3342L51.7041 21.2406C51.8098 20.5929 52.1269 20.2715 52.6025 20.2715C53.1837 20.2715 53.4528 20.6465 53.3471 21.3477L51.757 31.6563C51.5985 32.4598 51.4399 32.7861 50.7482 32.7861C50.0612 32.7861 49.9027 32.2991 50.0612 31.4956L50.8539 26.3925H41.2125L40.367 31.8706C39.5167 37.4559 42.1156 41.6972 49.4799 41.6972H51.4928C57.9011 41.6972 61.1342 37.7237 61.9796 32.5182L64.0453 19.3074C64.6266 15.441 63.0941 12.2175 59.1213 12.2175H58.7995C55.5713 12.2175 53.1837 14.472 52.3383 15.6553L53.2414 10.07H64.3624Z" fill="#D81921" />
						<path d="M79.8117 14.1166H79.1296C76.401 14.1166 75.0991 15.7089 74.5899 18.6159L74.1095 21.7616C74.0807 21.9661 74.0567 22.1658 74.0423 22.3557C75.3393 21.6691 76.8045 21.0945 78.457 20.666L78.923 17.6469C78.9566 17.4034 79.0287 17.16 79.3361 17.16C79.6436 17.16 79.6772 17.4034 79.6436 17.6469L79.2016 20.4858C80.4074 20.2229 81.8822 20.0281 83.5107 19.8917L83.7029 18.6159C84.1496 15.8452 82.8141 14.1166 79.8117 14.1166ZM71.4818 0H70.766C68.071 0 66.7404 1.55823 66.2263 4.49938L65.746 7.64505C65.304 10.5521 66.7019 12.2126 69.6035 12.2126H70.1847C72.8125 12.2126 74.3497 10.5862 74.8637 7.64505L75.3729 4.49938C75.8197 1.72866 74.489 0 71.4818 0ZM71.28 3.56444L70.4922 8.61407C70.4586 8.92572 70.3241 9.10102 70.1175 9.10102C69.81 9.10102 69.7428 8.85754 69.7764 8.61407L70.5594 3.56444C70.5979 3.32097 70.6988 3.0775 71.0062 3.0775C71.28 3.0775 71.3137 3.32097 71.28 3.56444ZM80.1864 0.311645L65.5442 26.0565H69.2288L83.8758 0.311645H80.1864Z" fill="#D81921" />
					</svg>
				</div>

				<div className={cn(styles.thirdSlide__descr, {
					[styles.thirdSlide__descr_show]: isShow
				})}>
					<p>за счет удлиненного компактного формата</p>
				</div>

				<div className={cn(styles.thirdSlide__legal, {
					[styles.thirdSlide__legal_show]: isShow
				})}>
					<p>* в среднем по сравнению с LD Autograph Club Compact Blue</p>
				</div>

				<div
					onClick={() => swiper.slideNext()}
					className={cn(styles.thirdSlide__next, {
						[styles.thirdSlide__next_show]: showNext
					})}>
					<img src={arrowNext} alt="" />
				</div>
			</div>
		</div>
	)
}

export default ThirdSlide;