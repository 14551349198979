import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";

import styles from './index.module.scss';

const Button = ({
	className = '',
	white = false,
	short = false,
	onClick,
	disabled,
	type,
	text,
	link = false,
	to = '',
}) => {

	if (link) {
		return (
			<Link
				to={to}
				className={cn(className, styles.btn, {
					[styles.btn_white]: white,
					[styles.btn_short]: short
				})}
			>
				{text}
			</Link>
		)
	}

	return (
		<button
			type={type}
			disabled={disabled}
			onClick={onClick}
			className={cn(className, styles.btn, {
				[styles.btn_white]: white,
				[styles.btn_short]: short
			})}>
			{text}
		</button>
	)
}

export default Button;