import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import cn from "classnames";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { addAnswer } from "../../store/answersSlice";

import Button from "../../components/UI/Button";
import Formfield from "../../components/UI/Formfield";
import Pointfield from "../../components/UI/Pointfield";

import { QUESTIONS_PATH, FINAL_PATH } from "../../constants";

import styles from './index.module.scss';

const Questions = ({ question, questionsLength, index }) => {
	const [hide, setHide] = useState(false)
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [selectActive, setSelectActive] = useState(false);
	const [jumpQuestion, setJumpQuestion] = useState(null);

	return (
		<Formik
			initialValues={{
				question_id: question.id,
				answer: '',
			}}
			onSubmit={(values) => {
				values.answer !== '' && dispatch(addAnswer(values))
				setHide(true);

				jumpQuestion !== null && jumpQuestion !== -1
					? setTimeout(() => navigate(`${QUESTIONS_PATH}${jumpQuestion}`), 800)
					: index !== questionsLength - 1 && setTimeout(() => navigate(`${QUESTIONS_PATH}${index + 1}`), 800);

				if (index === questionsLength - 1) {
					setTimeout(() => navigate(FINAL_PATH), 800);
				}
			}}
		>
			{values => (
				<Form key={question.id} className={styles.question}>
					<div className={cn(styles.question__wrap, {
						[styles.question__wrap_hide]: hide
					})}>
						<div className="container">
							<span className={styles.question__order}>
								{`${question.order}.`}
							</span>

							<h1 className={styles.question__title} dangerouslySetInnerHTML={{ __html: question.text }}></h1>

							{!!question.sub_text && <h2 className={styles.question__subtitle} dangerouslySetInnerHTML={{ __html: question.sub_text }}></h2>}


						</div>

						{/* <div className={styles.question__answers}>
							{question.answer_type === 'text' && (
								<Field
									name='answer'
									as='textarea'
									maxLength="200"
									className={styles.question__textarea} />
							)}

							{question.answer_type === 'points' && (
								<div className={styles.question__pointlist}>
									{question.answers.map(n => (
										<Pointfield
											key={n.order}
											formik={values}
											type='radio'
											jumpOnQuestion={n.jump_question}
											setJump={setJumpQuestion}
											label={n.text}
											name='answer'
											id={`q${n.id}`}
											value={n.text}
											classname={cn(styles.question__pointfield)} />
									))}
								</div>
							)}

							{question.answer_type === 'radio' && (
								<div className={styles.question__formlist}>
									{question.answers.map(n => (
										<Formfield
											key={n.order}
											formik={values}
											active={selectActive}
											setActive={setSelectActive}
											type='radio'
											jumpOnQuestion={n.jump_question}
											setJump={setJumpQuestion}
											others={n.others}
											label={n.text}
											name='answer'
											id={`q${n.id}`}
											value={n.text}
											classname={styles.question__formfield} />
									))}
								</div>
							)}
						</div> */}

						{question.answer_type === 'text' && (
							<Field
								name='answer'
								as='textarea'
								maxLength="200"
								className={styles.question__textarea} />
						)}

						{question.answer_type === 'points' && (
							<div className={styles.question__pointlist}>
								{question.answers.map(n => (
									<Pointfield
										key={n.order}
										formik={values}
										type='radio'
										jumpOnQuestion={n.jump_question}
										setJump={setJumpQuestion}
										label={n.text}
										name='answer'
										id={`q${n.id}`}
										value={n.text}
										classname={cn(styles.question__pointfield)} />
								))}
							</div>
						)}

						{question.answer_type === 'radio' && (
							<div className={styles.question__formlist}>
								{question.answers.map(n => (
									<Formfield
										key={n.order}
										formik={values}
										active={selectActive}
										setActive={setSelectActive}
										type='radio'
										jumpOnQuestion={n.jump_question}
										setJump={setJumpQuestion}
										others={n.others}
										label={n.text}
										name='answer'
										id={`q${n.id}`}
										value={n.text}
										classname={styles.question__formfield} />
								))}
							</div>
						)}

						<Button
							disabled={question.is_required && values.values.answer === ''}
							text='Продолжить'
							type='submit'
							short
							className={cn(styles.question__btn)}>
						</Button>
					</div>
				</Form>
			)}
		</Formik>
	)
}

export default Questions;