import React from "react";
import { Field } from "formik";
import cn from "classnames";
import { useSelector } from "react-redux";

import styles from './index.module.scss';

const Formfield = ({
	classname,
	type,
	label,
	name,
	id,
	value,
	formik,
	active,
	setActive,
	others,
	jumpOnQuestion,
	setJump
}) => {
	const questions = useSelector(state => state.questions);

	return (
		<>
			{
				label === 'Другое'
					? <div className={cn(classname, styles.formselect)}>
						<Field
							as='select'
							id={id}
							name={name}
							onChange={(e) => {
								formik.setFieldValue(name, e.target.value)
								setActive(true)
								setJump(questions.data.findIndex(q => +q.order === jumpOnQuestion))
							}}
							className={cn(styles.formselect__select, {
								[styles.formselect__select_active]: active,
							})}>

							<option defaultValue={label} hidden>{label}</option>
							{others.map(n => {
								return <option key={n.id} value={n.text}>{n.text}</option>
							})}
						</Field>
					</div>
					: <div className={cn(classname, styles.formfield)}>
						<Field
							onChange={(e) => {
								formik.setFieldValue(name, e.target.value)
								setActive(false)
								setJump(questions.data.findIndex(q => +q.order === jumpOnQuestion))
							}}
							id={id}
							type={type}
							name={name}
							value={value}
							className={styles.formfield__input}
						/>
						<label
							htmlFor={id}
							className={styles.formfield__label}
						><span>{label}</span></label>
					</div>
			}
		</>
	)
}

export default Formfield;