import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getQuestions } from "../../store/questionsSlice";

import FirstPage from "../../pages/FirstPage";
import SwiperPage from "../../pages/Swiper";
import ErrorAge from "../../pages/ErrorAge";
import SecPage from "../../pages/SecPage";
import Questions from "../../pages/Questions";
import Error from "../../pages/Error";
import ErrorDesktop from '../../pages/ErrorDesktop';
import FinalScreen from "../../pages/FinalScreen";

import pack from '../../assets/img/secPage/pack.png';
import bgPack from '../../assets/img/secPage/bgPack.png';
import title from '../../assets/img/secPage/title.svg';
import novelty from '../../assets/img/secPage/novelty.svg';
import info from '../../assets/img/finalInfo.svg';

import {
	HOME_PATH,
	SWIPER_PATH,
	ERRORAGE_PATH,
	SECPAGE_PATH,
	QUESTIONS_PATH,
	ERROR_PATH,
	FINAL_PATH,
} from "../../constants";

import '../../assets/styles/_ui.scss'
import '../../assets/styles/_fonts.scss'

import styles from './index.module.scss';

function App() {
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const questions = useSelector(state => state.questions);
	const [post, setPost] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	// preload imgs
	const cacheImages = async (srcArray) => {
		if (!!srcArray) {
			const promises = await srcArray.map(src => {
				return new Promise(function (resolve, reject) {
					const img = new Image();

					img.src = src;
					img.onload = resolve();
					img.onerror = reject();
				});
			});

			await Promise.all(promises)

			setIsLoading(false);
		}
	}

	useEffect(() => {
		const imgs = [
			pack,
			bgPack,
			title,
			novelty,
			info
		]

		cacheImages(imgs);
	}, []);

	useEffect(() => {
		dispatch(getQuestions());
	}, [])

	useEffect(() => {
		if (pathname.indexOf(process.env.REACT_APP_GLOBAL_HASH) !== -1) {
			navigate(HOME_PATH);
		} else {
			navigate(ERROR_PATH);
		}
	}, []);

	useEffect(() => {
		pathname.indexOf(FINAL_PATH) !== -1 && setPost(true);
	}, [pathname]);

	return (
		<main>
			<div className={styles.appDesktop}>
				<ErrorDesktop />
			</div>
			<div className={styles.appMobile}>
				<Routes>
					<Route path={ERRORAGE_PATH} element={<ErrorAge />} />
					<Route path={HOME_PATH} element={<FirstPage />} />
					<Route path={SECPAGE_PATH} element={<SecPage isLoaded={isLoading} />} />
					<Route path={SWIPER_PATH} element={<SwiperPage />} />
					{questions.isLoaded && questions.data.map((n, i) => {
						return (
							<Route
								key={n.id}
								path={`${QUESTIONS_PATH}${i}`}
								element={<Questions key={n.id} index={i} question={n} questionsLength={questions.data.length} />} />
						)
					})}

					<Route path={FINAL_PATH} element={<FinalScreen post={post} />} />
					<Route path={ERROR_PATH} element={<Error />} />
					<Route path='*' element={<Error />} />
				</Routes>
			</div>
		</main>

	)
}

export default App;
