import { configureStore } from '@reduxjs/toolkit';

import questionsReducer from './questionsSlice';
import answersReducer from './answersSlice';

export default configureStore({
	reducer: {
		questions: questionsReducer,
		answers: answersReducer,
	}
});