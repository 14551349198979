import React, { useState, useEffect } from "react";
import cn from "classnames";
import { useNavigate } from 'react-router-dom';

import errorAgeTop from '../../../assets/img/errorAgeTop.svg'
import errorAgeBottom from '../../../assets/img/errorAgeBottom.svg'
import phoneTop from '../../../assets/img/phoneTop.svg'
import phoneBottom from '../../../assets/img/phoneBottom.svg'
import arrowNextWhite from '../../../assets/img/swiper/arrowNextWhite.svg';

import { QUESTIONS_PATH } from "../../../constants";

import "swiper/css";

import styles from './index.module.scss';

const SixthSlide = ({ animate }) => {
	const [isShow, setShow] = useState(false);
	const [showArrow, setShowArrow] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		animate === 5 && setShow(true);

		setTimeout(() => {
			setShowArrow(true);
		}, 1200)
	}, [animate])

	return (
		<>
			{isShow && (
				<section className={styles.errorAge}>
					<div className={cn(styles.errorAge__bgTopWrap)}>
						<img src={errorAgeTop} alt="" className={styles.errorAge__bgTop} />
					</div>
					<div className={styles.errorAge__wrap}>
						<img src={phoneTop} alt="" className={styles.errorAge__top} />
						<img src={phoneBottom} alt="" className={styles.errorAge__bottom} />
						<img src={errorAgeBottom} alt="" className={styles.errorAge__bg} />
					</div>
					<div className={styles.errorAge__text}>
						<p>вы можете принять участие в опросе и получить вознаграждение на мобильный телефон*</p>
					</div>
					<div className={styles.errorAge__legal}>
						<p>* Вознаграждение может быть получено один раз при первичной регистрации</p>
					</div>
					<div
						onClick={() => navigate(`${QUESTIONS_PATH}0`)}
						className={cn(styles.errorAge__next, {
							[styles.errorAge__next_active]: showArrow
						})}>
						<img src={arrowNextWhite} alt="" />
					</div>
				</section>
			)}
		</>

	)
}

export default SixthSlide;