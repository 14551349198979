import React from "react";
import cn from "classnames";

import errorAgeTop from '../../assets/img/errorAgeTop.svg'
import errorAgeBottom from '../../assets/img/errorAgeBottom.svg'
import halfAgeTop from '../../assets/img/halfAgeTop.svg'
import halfAgeBottom from '../../assets/img/halfAgeBottom.svg'

import styles from './index.module.scss';

const ErrorAge = () => {
    return (
        <section className={styles.errorAge}>
            <div className={cn(styles.errorAge__bgTopWrap)}>
                <img src={errorAgeTop} alt="" className={styles.errorAge__bgTop} />
            </div>
            <div className={styles.errorAge__wrap}>
                <img src={halfAgeTop} alt="" className={styles.errorAge__top} />
                <img src={halfAgeBottom} alt="" className={styles.errorAge__bottom} />
                <img src={errorAgeBottom} alt="" className={styles.errorAge__bg} />
            </div>
            <div className={styles.errorAge__text}>
                <p>Данный сайт посвящен тАбачной продукции. Он доступен для просмотра только гражданам, достигшим совершеннолетия</p>
            </div>
        </section>
    )
}

export default ErrorAge;